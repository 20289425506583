
import { defineComponent, onMounted } from 'vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import AvailabilityGrid from '@/costumComponents/classe/AvailabilityGrid.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AvailabilityGrid-list',
  components: { AvailabilityGrid },
  props: {},
  setup() {
    const { t } = useI18n()

    onMounted(() => {
      setCurrentPageBreadcrumbs(t('availability.titleTeacher'), [])
    })

    return {}
  },
})
